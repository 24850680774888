import { CategoryPageParams } from 'features/kamino/connector';
import { FacetSearchResult } from 'types/Facet';
import useKaminoRetailMedia, { KaminoConfig } from './useKaminoRetailMedia';

type UseKaminoRetailMediaForCategoryParams = {
  categoryCode?: string;
  searchResults?: FacetSearchResult;
  // signings?: string;
};

export const useKaminoRetailMediaForCategory = (
  { categoryCode, searchResults }: UseKaminoRetailMediaForCategoryParams,
  config: KaminoConfig = {},
) => {
  // const isMobile = useMediaQuery('(max-width: 768px)');
  const canSendEvents = !!categoryCode && !!searchResults;
  // const { pagination, products: searchProducts = [] } = searchResults ?? {};
  // const { currentPage = 0, pageSize: listSize = 0 } = pagination ?? {};
  // const q = query?.q;

  // const pageNumber = currentPage + 1;
  // const solrQuery = Array.isArray(q) ? q[0] : q;
  // const productCodesString = searchProducts?.reduce(
  //   (prev: string, entry) => (entry.code ? pipeConcat(prev, entry.code) : prev),
  //   '',
  // );
  // const mappedFilters = [mapSolrQueryToCriteoFilters(solrQuery), mapSigningsToCriteoFilters(signings)]
  //   .filter(Boolean)
  //   .join(',');

  const params: CategoryPageParams = {
    page_id: categoryCode ?? '',
    page_type: 'Category',
  };

  return useKaminoRetailMedia(params, { ...config, enabled: (config.enabled ?? true) && canSendEvents });
};
