import { KAMINO_RETAILER_ID } from 'constants/kamino';
import { FetchKaminoCreativesConfig } from 'features/kamino/connector';
import { useKaminoCreatives } from 'features/kamino/queries';
import useRouter from 'hooks/useRouter';
import { mapKaminoBillboardPlacements, mapKaminoSponsoredProductsPlacements } from 'utils/kamino.util';
import { useKaminoTracking } from '../useKaminoTracking';

export type KaminoConfig = {
  enabled?: boolean;
  flagship?: {
    enabled?: boolean;
  };
  sponsoredProducts?: {
    enabled?: boolean;
  };
};

const useKaminoRetailMedia = (fetchKaminoCreativesParams?: FetchKaminoCreativesConfig, config: KaminoConfig = {}) => {
  const { userHasSufficientConsent, visitorId } = useKaminoTracking();
  const { locale } = useRouter();

  const { flagship: flagshipConfig, sponsoredProducts: sponsoredProductsConfig } = config;
  const flagshipEnabled = flagshipConfig?.enabled ?? true;
  const sponsoredProductsEnabled = sponsoredProductsConfig?.enabled ?? true;
  const enabled = (sponsoredProductsEnabled || flagshipEnabled) && userHasSufficientConsent;

  const params = fetchKaminoCreativesParams
    ? {
        ...fetchKaminoCreativesParams,
        language: locale,
        retailer: KAMINO_RETAILER_ID,
        'user[gdpr_consent]': userHasSufficientConsent ? 'true' : 'false',
        'user[user_id]': visitorId ?? '',
      }
    : undefined;

  const { data: kaminoResult } = useKaminoCreatives(params, enabled);
  if (!kaminoResult) return { flagshipPlacements: [], sponsoredProductsPlacements: [] };

  const sponsoredProductsPlacements = mapKaminoSponsoredProductsPlacements(kaminoResult?.ads);
  const billboardPlacements = mapKaminoBillboardPlacements(kaminoResult?.ads);

  return {
    billboardPlacements,
    sponsoredProductsPlacements,
  };
};
export default useKaminoRetailMedia;
